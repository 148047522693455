import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useLocalStorage, useLocation } from 'react-use';

import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import {
  selectLogin,
  selectLoginErrors,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  buildErrorNotification,
  getUniqErrorMessages,
} from '@savgroup-front-common/core/src/helpers';
import { useAppStatuses } from '@savgroup-front-common/core/src/molecules/AppStatuses';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import { PRODUCT_FRUITS_CONFIG } from '../../../configuration/productFruits';
import { signIn } from '../../../domains/login/actions';
import Layout from '../layout/Layout';

import { useAuth } from './AuthProviderContext';
import { useIsCypressRunning } from './CheckAuthorized.hooks';
import LoadingScreen from './LoadingScreen';

const CheckAuthorized = () => {
  const errors = useSelector(selectLoginErrors);
  const locale = useSelector(selectLocale);
  const { pathname, search } = useLocation();
  const invitationId = new URLSearchParams(search).get('invitation_id');
  const invitationToken = new URLSearchParams(search).get('invitation_token');
  const authenticatedUser = useSelector(selectLogin);

  const { user, isAuthenticated, login } = useAuth();
  const { isComponentStatusLoaded } = useAppStatuses();

  const dispatch = useDispatch();
  const isCypressRunning = useIsCypressRunning();

  useDeepCompareEffect(() => {
    if (user && isAuthenticated) {
      dispatch(signIn(() => undefined) as any);

      return;
    }

    setTimeout(() =>
      login({
        extraQueryParams:
          invitationId && invitationToken
            ? {
                invitation_id: invitationId,
                invitation_token: invitationToken,
              }
            : {},
        state: {
          redirectUrl: `${pathname}${search}`,
        },
      }),
    );
  }, [
    dispatch,
    invitationId,
    invitationToken,
    isAuthenticated,
    login,
    pathname,
    search,
    user,
  ]);

  const notifications = getUniqErrorMessages(errors || []).map(
    buildErrorNotification,
  );

  const [isNewUiEnabledFromStorage] = useLocalStorage(
    LOCAL_STORAGE_KEYS.NEW_BO_ENABLED,
    false,
    {
      raw: false,
      serializer: (value) => {
        if (value) {
          return 'true';
        }

        return 'false';
      },
      deserializer: (value) => {
        return value === 'true';
      },
    },
  );

  if (!user || !isAuthenticated || !isComponentStatusLoaded) {
    return <LoadingScreen notifications={notifications} />;
  }

  datadogRum.setUser({
    id: authenticatedUser.userId,
    name: `${authenticatedUser.firstname} ${authenticatedUser.lastname}`,
    email: authenticatedUser.mail,
    accesses: authenticatedUser.accesses,
    permissions: authenticatedUser.permissions,
  });

  const userInfo = {
    username: authenticatedUser.mail,
    email: authenticatedUser.mail,
    firstname: authenticatedUser.firstname,
    lastname: authenticatedUser.lastname,
    props: {
      roles: authenticatedUser.roles,
      isNewUi: isNewUiEnabledFromStorage,
    },
  };

  return (
    <>
      <Layout />

      {!isCypressRunning && PRODUCT_FRUITS_CONFIG.workspaceCode && (
        <ProductFruits
          workspaceCode={PRODUCT_FRUITS_CONFIG.workspaceCode}
          language={locale}
          user={userInfo as any}
        />
      )}
    </>
  );
};

export default CheckAuthorized;
