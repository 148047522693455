import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { userManager } from '@savgroup-front-common/core/src/services/userManager';

import { logError } from '../../../configuration';
import { signIn } from '../../../domains/login/actions';

import { useAuth } from './AuthProviderContext';
import LoadingScreen from './LoadingScreen';

const useOAuthServer = () => {
  const dispatch = useDispatch();

  const search = new URLSearchParams(window.location.search);
  const state = search.get('state');

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        if (!user && state) {
          await userManager.signinCallback();

          return;
        }

        const urlBeforeRedirection = (user.state as { redirectUrl?: string })
          ?.redirectUrl;

        if (urlBeforeRedirection && !urlBeforeRedirection?.startsWith('/')) {
          throw new Error('Invalid redirect URL');
        }

        dispatch(signIn(() => navigate(urlBeforeRedirection ?? '/')) as any);
      } catch (e) {
        logError(e);
      }
    })();
  }, [dispatch, navigate, state, user]);
};

export const Callback: React.FC = () => {
  useOAuthServer();

  return <LoadingScreen notifications={[]} />;
};
