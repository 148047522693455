import { rgba, setLightness } from 'polished';
import styled, { css } from 'styled-components';

import { Z_INDEX_VERTICAL_MENU } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import {
  simpleSlideFromBottomAnimation,
  simpleSlideFromTopAnimation,
} from '../../animations/simpleAnimation';
import { rem, styledGetCustomComponentProperty } from '../../helpers';
import { Link } from '../link';

import { MENU_POSITIONS } from './Menu.types';

const disabledCss = css`
  color: ${({ theme }) => theme.colors.paragraphTextColor}!important;
  background-color: transparent !important;
  cursor: not-allowed;
`;

interface MenuItemMixinProps {
  $color?: string;
  disabled?: boolean;
  $isDisabled?: boolean;
  $isNewUiEnabled?: boolean;
  $hasSecondaryAction?: boolean;
}

export const $MenuContent = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const $MenuTextContainer = styled.div`
  display: flex;

  flex-direction: column;
  *:nth-child(1) {
  }
  *:nth-child(2) {
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: ${({ theme }) => theme.fonts.size.mini};
    color: ${({ theme }) => theme.colors.default};
  }
`;

const menuItemMixin = css<MenuItemMixinProps>`
  padding: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled ? '6px 12px 6px 8px' : '0 1rem'};
  text-align: left;
  color: ${({ theme, $color, $isNewUiEnabled }) => {
    if ($color) {
      return $isNewUiEnabled ? $color : setLightness(0.75, $color);
    }

    if ($isNewUiEnabled) {
      return theme.colors.mainTextColor;
    }

    return theme.colors.white;
  }};
  background-color: transparent;
  width: 100%;
  height: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? 'unset' : rem(38))};
  font-size: ${({ theme }) => theme.fonts.size.small};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  outline: 0;
  white-space: nowrap;
  min-width: 14rem;
  cursor: pointer;

  svg {
    color: ${({ theme, $color, disabled, $isDisabled, $isNewUiEnabled }) => {
      if (disabled || $isDisabled) {
        return theme.colors.paragraphTextColor;
      }

      if ($isNewUiEnabled) {
        return $color ?? theme.newUI?.defaultColors?.secondary;
      }

      return $color ? setLightness(0.75, $color) : theme.colors.white;
    }};
  }

  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  &:focus,
  &:hover {
    color: ${({ theme, $color, $isNewUiEnabled }) => {
      if ($isNewUiEnabled) {
        return $color ?? theme.colors.mainTextColor;
      }

      if ($color) {
        return setLightness(0.75, $color);
      }

      return theme.colors.white;
    }};
    text-decoration: none;
    background-color: ${({ theme, $color, $isNewUiEnabled }) => {
      if ($isNewUiEnabled) {
        return theme.newUI.variationColors.light6;
      }

      return rgba(
        setLightness(0.7, $color || theme.colors.darkBackground),
        0.3,
      );
    }};
  }
  &:active {
    background-color: ${({ theme, $color }) =>
      rgba(setLightness(0.7, $color || theme.colors.darkBackground), 0.5)};
  }
  ${({ disabled, $isDisabled }) => (disabled || $isDisabled) && disabledCss}
`;

export const $MenuButtonContainer = styled.div<MenuItemMixinProps>`
  display: flex;
  justify-content: space-between;

  &:hover {
    color: ${({ theme, $color, $isNewUiEnabled }) => {
      if ($color) {
        return setLightness(0.75, $color);
      }

      if ($isNewUiEnabled) {
        return theme.colors.mainTextColor;
      }

      return theme.colors.white;
    }};
    text-decoration: none;
    background-color: ${({ theme, $color, $isNewUiEnabled }) => {
      if ($isNewUiEnabled) {
        return theme.newUI.variationColors.light6;
      }

      return rgba(
        setLightness(0.7, $color || theme.colors.darkBackground),
        0.3,
      );
    }};
  }

  & button:hover {
    background-color: transparent;
  }
`;

export const $MenuButton = styled.button<MenuItemMixinProps>`
  ${menuItemMixin}
`;
export const $MenuBack = styled.button<MenuItemMixinProps>`
  ${menuItemMixin};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  border-bottom: ${rem(1)} solid #666;
`;

export const $MenuSubMenu = styled.button<MenuItemMixinProps>`
  ${menuItemMixin};
  justify-content: space-between;
  svg:last-child {
    margin-right: -${rem(10)};
  }
`;

export const $MenuLink = styled(Link)<
  React.PropsWithChildren<MenuItemMixinProps>
>`
  ${menuItemMixin}
`;

interface MenuTransitionProps {
  $position?: MENU_POSITIONS | { top: string; left: string };
  $componentThemeName?: string;
  $isNewUiEnabled?: boolean;
}

export const $MenuTransition = styled.ul<MenuTransitionProps>`
  position: absolute;
  list-style: none;
  border-radius: ${({ $componentThemeName, theme, $isNewUiEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      });
    }

    if ($isNewUiEnabled) {
      return theme.newUI.borders.bigRadius;
    }

    return theme.borders.radius;
  }};

  background: ${({ $componentThemeName, theme, $isNewUiEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      });
    }

    if ($isNewUiEnabled) {
      return theme.systemColors.defaultBackground;
    }

    return theme.colors.darkBackground;
  }};

  color: ${({ $componentThemeName, theme, $isNewUiEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.COLOR,
      });
    }

    if ($isNewUiEnabled) {
      return theme.newUI.defaultColors.text;
    }

    return undefined;
  }};

  max-height: 455px;
  overflow-y: auto;

  margin-top: ${rem(2)};
  display: flex;
  flex-direction: column;
  padding: ${({ $componentThemeName, $isNewUiEnabled }) => {
    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.PADDING,
      });
    }

    if ($isNewUiEnabled) {
      return '.5rem';
    }

    return '0.25rem 0 0.25rem';
  }};

  min-height: ${rem(40)};
  pointer-events: auto;

  border: ${({ $componentThemeName }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.BORDER,
        })
      : null};

  gap: 6px;
  ${({ $position }) => {
    if ($position && typeof $position === 'object') {
      return css`
        top: ${$position.top};
        left: ${$position.left};
      `;
    }

    switch ($position) {
      case MENU_POSITIONS.TOP:
        return css`
          top: 0;
        `;
      case MENU_POSITIONS.BOTTOM:
        return css`
          top: 200%;
        `;
      case MENU_POSITIONS.LEFT:
        return css`
          left: 0;
        `;
      case MENU_POSITIONS.RIGHT:
        return css`
          right: 0;
        `;

      default: {
        return null;
      }
    }
  }};
  box-shadow: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled ? '0px 1px 4px 0px rgba(0, 0, 0, 0.1)' : null};
`;

export const $MenuTitle = styled.h6<{
  $isNewUiEnabled: boolean;
}>`
  padding: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled ? '0 12px 0 8px' : '0 1rem'};
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  width: 100%;
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.newUI.defaultColors.text : theme.colors.white};

  font-size: ${({ $isNewUiEnabled, theme }) =>
    $isNewUiEnabled ? theme.fonts.size.megaSmall : null};
  font-weight: ${({ $isNewUiEnabled, theme }) =>
    $isNewUiEnabled ? theme.fonts.weight.mediumBold : null};
`;

export const $MenuItemStyled = styled.li`
  width: 100%;
  ${simpleSlideFromBottomAnimation};
`;

interface $MenuContainerProps {
  $position?: MENU_POSITIONS | { top: string; left: string };
  animationDuration?: number;
}

export const $MenuContainer = styled.div<$MenuContainerProps>`
  ${simpleSlideFromTopAnimation};
  pointer-events: none;
  position: relative;
  z-index: ${Z_INDEX_VERTICAL_MENU};
  ${({ $position }) => {
    switch ($position) {
      case MENU_POSITIONS.BOTTOM:
        return css`
          right: 100%;
        `;

      default: {
        return null;
      }
    }
  }};
`;
