import React, { FunctionComponent, ReactNode, RefObject } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  LoaderIcon,
} from '../../protons/icons';

import {
  calculateEasing,
  VERTICAL_MENU_ANIMATION_DURATION,
} from './Menu.helpers';
import useMenu from './Menu.hooks';
import {
  $MenuBack,
  $MenuButton,
  $MenuButtonContainer,
  $MenuContent,
  $MenuItemStyled,
  $MenuLink,
  $MenuSubMenu,
  $MenuTextContainer,
  $MenuTitle,
} from './Menu.styles';
import { MENU_ITEM_TYPES, MENU_POSITIONS, MenuItem } from './Menu.types';
import MenuAnimator from './MenuAnimator';
import messages from './messages';

interface VerticalMenuitem {
  menuItems: MenuItem[];
  onClose?: () => void;
  position?: MENU_POSITIONS | { top: string; left: string };
  dataTestId?: string;
  wrapperRef?: RefObject<any>;
  wrapperRefs?: RefObject<any>[];
  componentThemeName?: string;
}
const VerticalMenuContent: FunctionComponent<
  React.PropsWithChildren<VerticalMenuitem>
> = ({
  menuItems = [],
  onClose = () => undefined,
  position,
  dataTestId,
  wrapperRef,
  wrapperRefs,
  componentThemeName,
}) => {
  const { disabledAnimation, list } = useMenu({
    onClose,
    menuItems,
    wrapperRef,
    wrapperRefs,
  });
  const theme = useTheme();
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <MenuAnimator
      position={position}
      dataTestId={dataTestId}
      componentThemeName={componentThemeName}
    >
      {list.map((menuItem, index) => {
        let component: ReactNode;

        switch (menuItem.type) {
          case MENU_ITEM_TYPES.CUSTOM:
            component = React.cloneElement(menuItem.children, { onClose });
            break;

          case MENU_ITEM_TYPES.TITLE:
            component = (
              <$MenuTitle
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.TITLE}`
                    : `menu_child_${MENU_ITEM_TYPES.TITLE}`
                }
                ref={menuItem.ref}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading && (
                    <LoaderIcon
                      size="32px"
                      color={
                        isNewUiEnabled
                          ? theme.colors.mainTextColor
                          : theme.colors.white
                      }
                    />
                  )}
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>
              </$MenuTitle>
            );
            break;

          case MENU_ITEM_TYPES.LINK:
            component = (
              <$MenuLink
                onClick={onClose}
                href={menuItem.href}
                ref={menuItem.ref}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.LINK}`
                    : `menu_child_${MENU_ITEM_TYPES.LINK}`
                }
                $color={menuItem.color}
                $isDisabled={menuItem.isDisabled}
                target={menuItem.target}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading ? (
                    <LoaderIcon size="32px" color={theme.colors.white} />
                  ) : (
                    menuItem.icon
                  )}
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>
              </$MenuLink>
            );
            break;

          case MENU_ITEM_TYPES.MENU:
            component = (
              <$MenuSubMenu
                onClick={menuItem.onClick}
                type={BUTTON_TYPES.BUTTON}
                disabled={menuItem.isDisabled}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.MENU}`
                    : `menu_child_${MENU_ITEM_TYPES.MENU}`
                }
                $color={menuItem.color}
                $isNewUiEnabled={isNewUiEnabled}
              >
                {menuItem.isLoading && (
                  <LoaderIcon size="20px" color={theme.colors.white} />
                )}
                {!menuItem.isLoading && menuItem.icon}

                <$MenuContent>
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>

                <ChevronRightIcon size="20px" color={theme.colors.white} />
              </$MenuSubMenu>
            );
            break;

          case MENU_ITEM_TYPES.BACK:
            component = (
              <$MenuBack
                onClick={menuItem.onClick}
                type={BUTTON_TYPES.BUTTON}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.BACK}`
                    : `menu_child_${MENU_ITEM_TYPES.BACK}`
                }
                $isNewUiEnabled={isNewUiEnabled}
              >
                <ChevronLeftIcon size="20px" />

                <$MenuContent>
                  <SafeFormattedMessageWithoutSpread message={messages.back} />
                </$MenuContent>
              </$MenuBack>
            );
            break;

          default:
            component = (
              <$MenuButtonContainer
                $isNewUiEnabled={isNewUiEnabled}
                $color={menuItem.color}
              >
                <$MenuButton
                  onClick={(e) => {
                    const response = { ...menuItem };

                    if (menuItem.onClick) {
                      menuItem.onClick(response, e);
                    }
                  }}
                  type={BUTTON_TYPES.BUTTON}
                  ref={menuItem.ref}
                  data-testid={menuItem.dataTestId || 'menu_child_button'}
                  disabled={menuItem.isDisabled}
                  $color={menuItem.color}
                  $isNewUiEnabled={isNewUiEnabled}
                  $hasSecondaryAction={!!menuItem.SecondaryAction}
                >
                  {menuItem.isLoading && (
                    <LoaderIcon
                      size="32px"
                      color={
                        isNewUiEnabled
                          ? theme.colors.mainTextColor
                          : theme.colors.white
                      }
                    />
                  )}
                  {!menuItem.isLoading && menuItem.icon}

                  <$MenuContent>
                    <$MenuTextContainer>
                      {SafeFormattedMessage(menuItem.label)}
                      {menuItem.description &&
                        SafeFormattedMessage(menuItem.description)}
                    </$MenuTextContainer>
                  </$MenuContent>
                </$MenuButton>

                {!!menuItem.SecondaryAction && menuItem.SecondaryAction}
              </$MenuButtonContainer>
            );
        }

        return (
          <$MenuItemStyled
            key={menuItem.key}
            data-testid={`menu_${menuItem.key}`}
            animationDuration={
              disabledAnimation ? 0 : VERTICAL_MENU_ANIMATION_DURATION
            }
            animationDelay={
              disabledAnimation ? 0 : calculateEasing(index, list.length)
            }
          >
            {component && React.cloneElement(component)}
          </$MenuItemStyled>
        );
      })}
    </MenuAnimator>
  );
};

export default VerticalMenuContent;
