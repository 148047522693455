import React, { Suspense } from 'react';
import {
  generatePath,
  Navigate,
  Routes as ReactRouterRoutes,
  Route,
} from 'react-router-dom';

import { PERMISSIONS, ROLES } from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import {
  useHasPermission,
  useHasSomeRoles,
  useIsSuperAdmin,
  useSearchParams,
} from '@savgroup-front-common/core/src/hooks';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import BaseLoader from '@savgroup-front-common/core/src/molecules/BaseLoader/BaseLoader';
import {
  PRICING_PLAN,
  REQUIRED_ONBOARDING_CONFIG_STEP,
  SELLER_PRODUCT_USAGE_CONTEXT,
} from '@savgroup-front-common/types';
import useHasSettingsPagePermission from 'control/view/pages/settings/hooks/useHasSettingsPagePermission';

import { BASES, ROUTES } from '../../../constants/routing';
import useHasBusinessIntelligencePermission from '../../../hooks/useHasBusinessIntelligencePermission';
import { ChoosePlanDrawer } from '../../components/ChoosePlanDrawer/ChoosePlanDrawer';
import useHasSellerSettingsPagePermission from '../../components/Settings/SellerSettings/SellerSettings.hooks';
import { useAppContext } from '../App.context';

import useEnvFavicon from './hooks/useEnvFavicon';
import useLoadAlerts from './hooks/useLoadAlerts';
import useLoadTodos from './hooks/useLoadTodos';
import messages from './messages';
import ShippingBoRedirectRoutes from './ShippingBoRedirectRoutes';

const SettingsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/settings')),
);
const NewSettingsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/NewSettings')),
);
const AlertsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/AlertsPage')),
);
const FilesPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPage/FilesPage')),
);
const IssueAQuotationPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/IssueAQuotationPage')),
);

const FilesHistoricPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPage/FilesHistoricPage')),
);
const BulkChangesPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BulkChangesPage')),
);
const TodosPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/TodosPage')),
);
const MarketplaceAggregator = React.lazy(() =>
  retryImportChunk(() => import('../../pages/MarketplaceAggregator')),
);
const OrderPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/orders')),
);
const OrderPageNewUi = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/orders/OrderPageNewUi/OrderPageNewUi'),
  ),
);
const SparePartPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/sparePart')),
);
const BusinessIntelligencePage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BusinessIntelligencePage')),
);
const ConfigurationConsolePage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/ConfigurationConsole')),
);

const BillingPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BillingPage')),
);

const DebugInspectorPage = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/ConfigurationConsole/DebugInspectorPage'),
  ),
);
const WelcomePage = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/Onboarding/WelcomePage/WelcomePage'),
  ),
);

const FromScratch = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/Onboarding/FromScratch/FromScratch'),
  ),
);

const FilesPageNewUI = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPageNewUI/FilesPage')),
);

const Routes = () => {
  const { selectedSeller } = useAppContext();

  useLoadAlerts();
  useLoadTodos();
  useEnvFavicon();

  const hasSettingsPagePermissions = Object.values(
    useHasSettingsPagePermission(),
  ).includes(true);
  const hasBusinessIntelligencePermission =
    useHasBusinessIntelligencePermission();

  const hasInspectorAccessRights = useHasPermission(
    PERMISSIONS.INSPECTOR_ACCESS_RIGHTS,
  );
  const isNewUiEnabled = useIsNewUiEnabled();

  const hasSellerSettingsPermissions = useHasSellerSettingsPagePermission();

  const hasUserManagementPermission = useHasPermission(
    PERMISSIONS.ROLE_MANAGEMENT,
  );
  const haseSanityTestsSettingsPermission = useHasPermission(
    PERMISSIONS.SANITY_TESTS,
  );
  const hasImportsPermission = useHasPermission(PERMISSIONS.IMPORT_MANAGEMENT);
  const hasConsoleAccessRightsPermission = useHasPermission(
    PERMISSIONS.CONSOLE_ACCESS_RIGHTS,
  );
  const isAdminDemo = useHasSomeRoles([
    ROLES.DEMO_ADMINISTRATOR,
    ROLES.ADMINISTRATOR,
  ]);
  const isSuperAdmin = useIsSuperAdmin();

  const hasSomeSettingsPermissions =
    isAdminDemo ||
    hasSellerSettingsPermissions ||
    hasUserManagementPermission ||
    haseSanityTestsSettingsPermission ||
    hasImportsPermission ||
    hasConsoleAccessRightsPermission;

  const isFromScratchSeller =
    selectedSeller?.sellerProductUsageContext ===
      SELLER_PRODUCT_USAGE_CONTEXT.PRODUCTION &&
    selectedSeller?.requiredOnboardingConfigStep !==
      REQUIRED_ONBOARDING_CONFIG_STEP.NONE &&
    selectedSeller?.requiredOnboardingConfigStep !==
      REQUIRED_ONBOARDING_CONFIG_STEP.PAYMENT_METHOD;

  const isBasicPricingPlan = selectedSeller?.pricingPlan === PRICING_PLAN.NONE;

  const query = useSearchParams();
  const code = query.get('code') || '';

  if (isFromScratchSeller && isNewUiEnabled) {
    return (
      <Suspense fallback={<BaseLoader messageList={[messages.pleaseWait]} />}>
        <ReactRouterRoutes>
          <Route path={`${BASES.FROM_SCRATCH}/*`} element={<FromScratch />} />
          <Route
            path={'*'}
            element={
              <Navigate
                to={buildUrl(generatePath(ROUTES.FROM_SCRATCH), { code })}
              />
            }
          />
        </ReactRouterRoutes>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<BaseLoader messageList={[messages.pleaseWait]} />}>
      <ChoosePlanDrawer />

      <ReactRouterRoutes>
        {isNewUiEnabled && (
          <>
            <Route
              path={`${BASES.TODOS}/*`}
              element={<Navigate to={ROUTES.TODOS_NEW_UI} />}
            />
            <Route
              path={`${BASES.ALERTS}/*`}
              element={<Navigate to={ROUTES.TODOS_NEW_UI} />}
            />

            <Route
              path={`${ROUTES.FILES_NEW_UI}/*`}
              element={<FilesPageNewUI />}
            />

            <Route path={`${BASES.ORDERS}/*`} element={<OrderPageNewUi />} />

            {hasSomeSettingsPermissions && (
              <>
                <Route
                  path={`${BASES.SETTINGS}/*`}
                  element={<NewSettingsPage />}
                />
                <Route
                  path={ROUTES.SHIPPING_BO}
                  element={
                    <ShippingBoRedirectRoutes
                      isFromScratchSeller={isFromScratchSeller}
                    />
                  }
                />
              </>
            )}

            {(isBasicPricingPlan || isSuperAdmin) && (
              <Route path={ROUTES.WELCOME_PAGE} element={<WelcomePage />} />
            )}

            <Route
              path={`${ROUTES.FROM_SCRATCH}/*`}
              element={<FromScratch />}
            />
          </>
        )}

        {!isNewUiEnabled && (
          <>
            <Route path={`${BASES.ALERTS}/*`} element={<AlertsPage />} />
            <Route path={`${BASES.TODOS}/*`} element={<TodosPage />} />
            <Route path={`${BASES.FILES}/*`} element={<FilesPage />} />

            <Route path={ROUTES.MESSAGES} element={<MarketplaceAggregator />} />

            <Route path={`${BASES.ORDERS}/*`} element={<OrderPage />} />

            {hasSettingsPagePermissions && (
              <Route path={`${BASES.SETTINGS}/*`} element={<SettingsPage />} />
            )}

            <Route
              path={ROUTES.FILES_HISTORIC}
              element={<FilesHistoricPage />}
            />
            <Route path={ROUTES.BULK_CHANGES} element={<BulkChangesPage />} />
          </>
        )}

        <Route
          path={`${BASES.CONFIGURATION_CONSOLE}/:sellerId/*`}
          element={<ConfigurationConsolePage />}
        />

        <Route path={`${BASES.SPARE_PARTS}/*`} element={<SparePartPage />} />

        <Route
          path={ROUTES.ISSUE_A_QUOTATION}
          element={<IssueAQuotationPage />}
        />

        <Route path={`${BASES.BILLING}/*`} element={<BillingPage />} />

        {hasBusinessIntelligencePermission && (
          <Route
            path={ROUTES.BUSINESS_INTELLIGENCE}
            element={<BusinessIntelligencePage />}
          />
        )}
        {hasInspectorAccessRights && (
          <Route
            path={ROUTES.DEBUG_INSPECTOR}
            element={<DebugInspectorPage />}
          />
        )}

        <Route
          path={ROUTES.HOME}
          element={
            <Navigate
              to={(() => {
                if (!isBasicPricingPlan && isNewUiEnabled && !isSuperAdmin) {
                  return generatePath(ROUTES.FILES_NEW_UI);
                }
                if ((isBasicPricingPlan && isNewUiEnabled) || isSuperAdmin) {
                  return generatePath(ROUTES.WELCOME_PAGE);
                }

                return generatePath(ROUTES.TODOS_WITH_PARAMS);
              })()}
            />
          }
        />

        <Route element={<Navigate to={ROUTES.HOME} />} />
      </ReactRouterRoutes>
    </Suspense>
  );
};

export default Routes;
